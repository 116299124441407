/* Importing all CSS files */
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-9');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;700&display=swap');

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/HelveticaNeue Light.ttf');
}

@font-face {
  font-family: 'HelveticaBold';
  src: url('../assets/fonts/HelveticaNeueBd.ttf');
}

@font-face {
  font-family: 'HelveticaMedium';
  src: url('../assets/fonts/HelveticaNeue Medium.ttf');
}

@import url('./Navbar.css');
@import url('./Home.css');
@import url('./Footer.css');
@import url('./Login.css');
@import url('./Sidebar.css');
@import url('./Popups.css');
@import url('./AdminTable.css');
@import url('./ServiceTable.css');
@import url('./Technician.css');
@import url('./Tank.css');
@import url('./CheckList.css');

/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Helvetica';
}

body {
  background-color: #d9f4ff;
}

/* Primary Variables */
:root {
  --primary: 'green';
}
